<template>
  <div class="home">
    <h1>Penalty Assessment Search</h1>

    <v-snackbar
      top
      multi-line
      :color="snackbar.color"
      v-model="snackbar.visible"
    >
      {{this.snackbar.text}}
    </v-snackbar>

    <v-row>
      <v-col cols="12" md="4">
        <v-text-field
          v-model="assessmentSearch.evaluationNumber"
          label="Evaluation Number"
          clearable
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          v-model="assessmentSearch.providerNumber"
          label="HFID / Provider Number"
          clearable
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          v-model="assessmentSearch.invoiceNumber"
          label="Invoice Number"
          clearable
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="3"/>
      <v-col cols="12" md="3">
        <v-btn
          large
          color="primary"
          @click="loadTable">
          Search
        </v-btn>
      </v-col>
      <v-col cols="12" md="3">
        <v-btn
          large
          color="primary"
          @click="addItem">
          Add
        </v-btn>
      </v-col>
      <v-col cols="12" md="3"/>
    </v-row>

    <v-data-table
      style="margin-top: 60px"
      :headers="headers"
      :items="assessments"
      item-key="uniqueName"
      :server-items-length="totalElements"
      loading-text="Loading... Please wait"
      class="elevation-1"
      caption="Penalty Assessments"
    >
      <template #top>
        <v-dialog
          persistent
          v-model="actionDialog"
          max-width="1080px"
        >
          <v-form v-model="valid">
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ action }} Assessment</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="4">
                      <v-text-field
                        v-model="editedItem.evaluationNumber"
                        label="Evaluation Number"
                        :disabled="action === 'Update'"
                      />
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field
                        v-model="editedItem.description"
                        label="Description"
                      />
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field
                        v-model="editedItem.assessment"
                        label="Assessment"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="editedItem.providerNumber"
                        label="HFID / Provider Number"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="editedItem.invoiceNumber"
                        label="Invoice Number"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="editedItem.tagNumber"
                        label="Tag Number"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="editedItem.statute"
                        label="Statute"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="editedItem.part"
                        label="Part"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="editedItem.subpart"
                        label="Subpart"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="editedItem.item"
                        label="Item"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="editedItem.subitem"
                        label="Subitem"
                      />
                    </v-col>
                  </v-row>

                  <v-row v-if="action === 'Update'">
                    <v-col>
                      <v-btn
                        @click="deleteDialog = true"
                        color="#b60000"
                        class="white--text"
                      >
                        Delete
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer/>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="saveItem"
                  :disabled="!valid"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>
      </template>

      <template #header>
        <v-dialog
          persistent
          v-model="deleteDialog"
          max-width="400px"
        >
          <v-form v-model="valid">
            <v-card>
              <v-card-title>
                <span class="text-h5">Confirm Delete</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    Are you sure you want to delete?
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="deleteItem"
                  :disabled="!valid"
                >
                  Yes
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="deleteDialog = false"
                >
                  No
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>
      </template>

      <template #item.actions="{ item }">
        <v-icon
          class="mr-2"
          @click="editItem(item)"
          aria-label="Edit"
          aria-hidden="false"
        >
          mdi-pencil
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
  import { evalApi } from "@/plugins/axios";

  export default {
    watch: {
      options : {
        deep: true
      }
    },

    created() {
      this.loadTable()
    },

    data() {
      return {
        assessments: [],
        assessmentSearch: {},
        totalElements: 0,
        valid: true,
        actionDialog: false,
        action: "",
        deleteDialog: false,

        snackbar: {
          visible: false,
          color: "#2a602c",
          text: ""
        },

        options: {
          itemsPerPage: 15,
          page: 1
        },
        editedItem: {},
        defaultItem: {},
        headers: [
          {text: "Edit", value: "actions", sortable: false},
          {text: "Evaluation Number", value: "evaluationNumber", sortable: false},
          {text: "HFID / Provider Number", value: "providerNumber", sortable: false},
          {text: "Invoice Number", value: "invoiceNumber", sortable: false},
          {text: "Tag Number", value: "tagNumber", sortable: false},
          {text: "Description", value: "description", sortable: false},
          {text: "Statute", value: "statute", sortable: false},
          {text: "Part", value: "part", sortable: false},
          {text: "Subpart", value: "subpart", sortable: false},
          {text: "Item", value: "item", sortable: false},
          {text: "Subitem", value: "subitem", sortable: false},
          {text: "Assessment", value: "assessment", sortable: false}
        ]
      }
    },

    methods: {
      async loadTable() {
        let res = await evalApi.post(`/penaltyAssessment/search-for-assessments`, {
          evaluationNumber: this.assessmentSearch.evaluationNumber,
          providerNumber: this.assessmentSearch.providerNumber,
          invoiceNumber: this.assessmentSearch.invoiceNumber,
          page: this.options.page,
          size: this.options.itemsPerPage,
        });
        this.assessments = res.data.content;
        this.totalElements = res.data.totalSize;
      },

      addItem() {
        this.actionDialog = true;
        this.action = "Add";
      },

      editItem(item) {
        this.editedItem = Object.assign({}, item);
        this.actionDialog = true;
        this.action = "Update";
      },

      async saveItem() {
        try {
          if (this.action === 'Add') {
            let evalNum = this.editedItem.evaluationNumber ? this.editedItem.evaluationNumber.trim() : null;
            this.editedItem = { ...this.editedItem, id: crypto.randomUUID(), evaluationNumber: evalNum };

            if (evalNum) {
              let res = await evalApi.get(`/evaluationsImport/evalNumExists/` + evalNum);
              if (!res.data) {
                this.showErrorPopup("Evaluation " + evalNum + " does not exist.");
                return;
              }
            }
          }

          if (!this.editedItem.evaluationNumber && !this.editedItem.providerNumber && !this.editedItem.invoiceNumber) {
            this.showErrorPopup("Cannot save penalty assessment. Please provide evaluation number, HFID, or invoice number.");
            return;
          }

          await evalApi.post(`/penaltyAssessment/save`, { penaltyAssessments: [this.editedItem] });
          this.close();
          this.loadTable();
          this.showSuccessPopup("Your penalty assessments have been saved.");
        } catch(error) {
          this.showErrorPopup("An error occurred during saving process: " + error);
        }
      },

      async deleteItem() {
        try {
          await evalApi.post(`/penaltyAssessment/delete`, { penaltyAssessmentIds: [this.editedItem.id] });
          this.deleteDialog = false;
          this.loadTable();
          this.close();

          this.snackbar.color = "#2a602c";
          this.snackbar.text = "Your penalty assessments have been saved.";
          this.snackbar.visible = true;
        } catch(error) {
          this.snackbar.color = "#b60000";
          this.snackbar.text = "An error occurred during saving process: " + error;
          this.snackbar.visible = true;
        }
      },

      close() {
        this.actionDialog = false;
        this.action = "";
        this.editedItem = Object.assign({}, this.defaultItem);
      },

      showErrorPopup(message) {
        this.snackbar.color = "#b60000";
        this.snackbar.text = message;
        this.snackbar.visible = true;
      },

      showSuccessPopup(message) {
        this.snackbar.color = "#2a602c";
        this.snackbar.text = message;
        this.snackbar.visible = true;
      }
    }
  }
</script>

<style>
  .v-input__control {
    padding: 10px 10px 10px 10px;
  }
</style>
